import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { UserAuthForm } from '@/components/react/forms/user-auth-form';
import { LogoV2 } from '../Logo';
import { Anchor } from '@/components/ui/anchor';

export const metadata = {
  title: 'Spider: Login',
  description: 'Login to spider and get the fastest scraper with AI abilities now.',
};

export const metadataRegister = {
  title: 'Spider: Register',
  description: 'Register with spider and get the fastest scraper with AI abilities now.',
};

// auth form that can put up supabase auth
export const AuthenticationPage = ({ registering }: { registering?: boolean }) => {
  return (
    <div className="h-[100vh] container relative flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0 py-12">
      <Anchor
        href={registering ? '/login' : '/register'}
        className={cn(
          buttonVariants({ variant: 'ghost' }),
          'absolute right-4 top-4 md:right-8 md:top-8'
        )}
      >
        {registering ? 'Login' : 'Register'}
      </Anchor>
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <Anchor className="hover:opacity-50" href="/">
          <div className="relative z-20 flex items-center text-lg font-medium gap-2">
            <LogoV2 height={30} width={30} noinvert className="fill-white dark:fill-black" />
            Spider
          </div>
        </Anchor>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">
              &ldquo;Spider handles the processing and extracting so fast and affordable it is a
              game changer for AI development.&rdquo;
            </p>
            <footer className="text-sm">Chad Won (AI Engineer)</footer>
          </blockquote>
        </div>
      </div>
      <div className="p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              {registering ? 'Create an account' : 'Login to your account'}
            </h1>
            <p className="text-sm text-muted-foreground">
              Enter your email below to create your account
            </p>
          </div>
          <UserAuthForm registering={registering} />
          <p className="px-8 text-center text-sm text-muted-foreground">
            By continuing, you agree to our{' '}
            <Anchor href="/eula" className="underline underline-offset-4 hover:text-primary">
              Terms of Service
            </Anchor>{' '}
            and{' '}
            <Anchor href="/privacy" className="underline underline-offset-4 hover:text-primary">
              Privacy Policy
            </Anchor>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
